/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/_theming.scss';
// Plus imports for other components in your app.
// Include non-theme styles for core.
@include mat-core();

// Define a theme.
$primary: mat-palette($mat-green);
$accent:  mat-palette($mat-blue, A200, A100, A400);

$theme: mat-light-theme($primary, $accent);

// Include all theme styles for the components.
@include angular-material-theme($theme);

$primary: map-get($theme, primary);
$background: map-get($theme, background);

.background-selected {
    background-color:  mat-color($background, background);
    color: mat-color($primary);
}

mat-nav-list a[mat-list-item].nav-active .mat-list-item{
    @extend .background-selected;
}

.wj-state-selected {
    background-color: #c0c7ca !important;
    color: #fff;
}

.wj-state-multi-selected {
    background-color: #b5bec2 !important;
    color: #fff;
}

.color_white { color: #FFFFFF; }

.mat-card{
    min-height: 400px;
}

body{
    overflow-y: auto;
}